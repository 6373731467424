export const initInputForm = {
  date: {
    value: '',
    col: '6',
    type: 'date',
    required: true,
    label: 'Datum:',
    key: 'date',
    menu: 'dateMenu',
  },
  type: {
    value: '',
    col: '6',
    type: 'select',
    required: true,
    label: 'Terminart:',
    key: 'type',
  },
  timeFrom: {
    value: '',
    col: '6',
    type: 'time',
    required: true,
    label: 'Von:',
    key: 'timeFrom',
  },
  timeTo: {
    value: '',
    col: '6',
    type: 'time',
    required: false,
    label: 'Bis:',
    key: 'timeTo',
  },
  name: {
    value: '',
    col: '6',
    type: 'text',
    required: false,
    label: 'Name',
    key: 'name',
  },
  telephone: {
    value: '',
    col: '6',
    type: 'number',
    required: false,
    label: 'Telefonnummer',
    key: 'telephone',
  },
  company: {
    value: '',
    col: '6',
    type: 'text',
    required: false,
    label: 'Firma',
    key: 'company',
  },
  email: {
    value: '',
    col: '6',
    type: 'email',
    required: false,
    label: 'Email',
    key: 'email',
  },
  information: {
    value: '',
    col: '12',
    type: 'textarea',
    required: false,
    label: 'Ihre Nachricht',
    key: 'information',
  },
};

export const initErrorForm = {
  date: null,
  type: null,
  timeTo: null,
  timeFrom: null,
  information: null,
  name: null,
  telephone: null,
  company: null,
  email: null,
};

export const initErrorMsg = {
  date: '',
  type: '',
  timeTo: '',
  timeFrom: '',
  information: '',
  name: '',
  telephone: '',
  company: '',
  email: '',
};

export const initAddCloseInputForm = {
  startDate: {
    value: '',
    col: '6',
    type: 'date',
    required: true,
    label: 'Start:',
    key: 'startDate',
    menu: 'dateMenu',
    description: 'Wählen Sie für einen Tag dasselbe Start- und Enddatum aus',
  },
  endDate: {
    value: '',
    col: '6',
    type: 'date',
    required: true,
    label: 'End:',
    key: 'endDate',
    menu: 'dateMenu',
    description: 'Wählen Sie für einen Tag dasselbe Start- und Enddatum aus',
  },
  type: {
    value: '',
    col: '6',
    type: 'select',
    required: true,
    label: 'Raum:',
    key: 'type',
  },
  information: {
    value: '',
    col: '12',
    type: 'textarea',
    required: false,
    label: 'Ihre Nachricht',
    key: 'information',
  },
};

export const initAddCloseErrorForm = {
  day: null,
  type: null,
  information: null,
};

export const initAddCloseErrorMsg = {
  day: '',
  type: '',
  information: '',
};

export const arenaTableFields = [
  { key: 'recurring', label: '', class: 'text-center' },
  { key: 'bookingDay', label: 'Datum', class: 'text-center' },
  { key: 'timeFrom', label: 'Von', class: 'text-center' },
  { key: 'timeTo', label: 'Bis', class: 'text-center' },
  { key: 'activityName', label: 'Terminart', class: 'text-center' },
  { key: 'information', label: 'Information' },
  { key: 'displayStatus', label: 'Displayed' },
  { key: 'function', label: 'function', class: 'text-center action-col' },
];

export const golfTableFields = [
  { key: 'recurring', label: '', class: 'text-center' },
  { key: 'bookingDay', label: 'Datum', class: 'text-center' },
  { key: 'timeFrom', label: 'Von', class: 'text-center' },
  { key: 'timeTo', label: 'Bis', class: 'text-center' },
  { key: 'name', label: 'Name', class: 'text-center' },
  { key: 'company', label: 'Firma', class: 'text-center' },
  { key: 'displayStatus', label: 'Displayed' },
  { key: 'function', label: 'function', class: 'text-center action-col' },
];

export const golfGameTableFields = [
  { key: 'recurring', label: '', class: 'text-center' },
  { key: 'bookingDay', label: 'Datum', class: 'text-center' },
  { key: 'timeFrom', label: 'Von', class: 'text-center' },
  { key: 'timeTo', label: 'Bis', class: 'text-center' },
  { key: 'activityName', label: 'Terminart', class: 'text-center' },
  { key: 'name', label: 'Name', class: 'text-center' },
  { key: 'company', label: 'Firma', class: 'text-center' },
  { key: 'displayStatus', label: 'Displayed' },
  { key: 'function', label: 'function', class: 'text-center action-col' },
];

export const adventureGolfTableFields = [
  { key: 'bookingDay', label: 'Datum', class: 'text-center' },
  { key: 'timeFrom', label: 'Von', class: 'text-center' },
  { key: 'timeTo', label: 'Bis', class: 'text-center' },
  { key: 'activityName', label: 'Terminart', class: 'text-center' },
  { key: 'teamSize', label: 'Size', class: 'text-center' },
  { key: 'displayStatus', label: 'Displayed' },
  { key: 'function', label: 'function', class: 'text-center action-col' },
];