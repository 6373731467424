<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="size"
    :height="size"
  ><path
    :fill="color"
    d="M20 6.91L17.09 4L12 9.09L6.91 4L4 6.91L9.09 12L4 17.09L6.91 20L12 14.91L17.09 20L20 17.09L14.91 12L20 6.91Z"
  /></svg>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: '20px',
    },
    color: {
      type: String,
      default: '#333333',
    },
  },
};
</script>
