<template>
  <div class="admin-booking-page">
    <div class="custom-container">
      <b-row>
        <b-col
          class="tool-column"
          lg="4"
        >
          <div class="search-date-container">
            <h4 class="title">
              Suche per Datum
            </h4>
            <v-date-picker
              id="adminBookingCalendar"
              v-model="selectDate"
              :disabled="isLoading"
              class="mt-4"
              locale="de"
              color="#F09009"
              @input="loadEventTable(false)"
            />
          </div>
        </b-col>
        <b-col lg="8">
          <b-tabs
            v-model="tabIndex"
            content-class="mt-3"
          >
            <b-tab
              title="Golf & SkillCourt"
              active
            >
              <booking-table
                :items="golfGameTableItems"
                :fields="golfGameTableFields"
                :is-loading="isLoading"
                type="Golf & SkillCourt"
                @search="onSearchTable"
                @create="onCreateEvent"
                @click-detail="onClickDetail"
                @edit="onEdit"
                @delete="onDelete"
                @reload="loadEventTable"
              />
            </b-tab>
            <b-tab title="Arena">
              <booking-table
                :items="arenaTableItems"
                :fields="arenaTableFields"
                :is-loading="isLoading"
                type="Arena"
                @search="onSearchTable"
                @create="onCreateEvent"
                @click-detail="onClickDetail"
                @edit="onEdit"
                @delete="onDelete"
                @reload="loadEventTable"
              />
            </b-tab>
            <b-tab title="Golf Room">
              <booking-table
                :items="golfTableItems"
                :fields="golfTableFields"
                :is-loading="isLoading"
                type="Golf Room"
                @search="onSearchTable"
                @create="onCreateEvent"
                @click-detail="onClickDetail"
                @edit="onEdit"
                @delete="onDelete"
                @reload="loadEventTable"
              />
            </b-tab>
            <b-tab title="SkillCourt">
              <booking-table
                :items="gameTableItems"
                :fields="golfTableFields"
                :is-loading="isLoading"
                type="SkillCourt"
                @search="onSearchTable"
                @create="onCreateEvent"
                @click-detail="onClickDetail"
                @edit="onEdit"
                @delete="onDelete"
                @reload="loadEventTable"
              />
            </b-tab>
            <b-tab title="Adventure">
              <booking-table
                :items="adventureGolfItems"
                :fields="adventureGolfTableFields"
                :is-loading="isLoading"
                type="Adventure Golf"
                @search="onSearchTable"
                @create="onCreateEvent"
                @click-detail="onClickDetail"
                @edit="onEdit"
                @delete="onDelete"
                @reload="loadEventTable"
              />
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </div>
    <base-modal
      id="admin-create-event-modal"
      title="Neuer Termin"
      custom-class="admin-create-event-modal"
      size="lg"
      admin
      no-close-on-backdrop
    >
      <b-row class="booking-form">
        <b-col :md="bookingInputForm.date.col">
          <b-form-group
            :label="bookingInputForm.date.label"
            :label-for="`input-${bookingInputForm.date.key}`"
            label-cols-sm="3"
            :state="isErrorBookingForm[bookingInputForm.date.key]"
            :invalid-feedback="errorMsgBookingForm[bookingInputForm.date.key]"
            :class="isHideDateNews ? 'disabled' : null"
          >
            <b-form-datepicker
              :id="`input-${bookingInputForm.date.key}`"
              v-model="bookingInputForm[bookingInputForm.date.key].value"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              :min="today"
              locale="de"
              @input="onInputDateBookingForm"
            />
          </b-form-group>
        </b-col>
        <b-col :md="bookingInputForm.type.col">
          <b-form-group
            :label="bookingInputForm.type.label"
            :label-for="`input-${bookingInputForm.type.key}`"
            :state="isErrorBookingForm[bookingInputForm.type.key]"
            :invalid-feedback="errorMsgBookingForm[bookingInputForm.type.key]"
            class="type-select"
            label-cols-sm="3"
          >
            <b-form-select
              :id="`input-${bookingInputForm.type.key}`"
              v-model="bookingInputForm[bookingInputForm.type.key].value"
              :options="typeOptions"
              @input="isErrorBookingForm[bookingInputForm.type.key] = null"
            />
          </b-form-group>
        </b-col>
        <b-col :md="bookingInputForm.timeFrom.col">
          <b-form-group
            :label="bookingInputForm.timeFrom.label"
            :label-for="`input-${bookingInputForm.timeFrom.key}`"
            label-cols-sm="3"
            :state="isErrorBookingForm[bookingInputForm.timeFrom.key]"
            :invalid-feedback="errorMsgBookingForm[bookingInputForm.timeFrom.key]"
            :class="isHideDateNews ? 'disabled' : null"
          >
            <vue-timepicker
              :id="`input-${bookingInputForm.timeFrom.key}`"
              ref="timeFromRef"
              v-model="bookingInputForm[bookingInputForm.timeFrom.key].value"
              :hour-range="timeFromHourRange"
              :minute-interval="timeFromMinuteInterval"
              hide-disabled-hours
              @input="onInputTimeFrom"
            />
          </b-form-group>
        </b-col>
        <b-col :md="bookingInputForm.timeTo.col">
          <b-form-group
            :label="bookingInputForm.timeTo.label"
            :label-for="`input-${bookingInputForm.timeTo.key}`"
            label-cols-sm="3"
            :state="isErrorBookingForm[bookingInputForm.timeTo.key]"
            :invalid-feedback="errorMsgBookingForm[bookingInputForm.timeTo.key]"
            :class="[
              (isHideDateNews
                || isTimeFromIncomplete
              ) ? 'disabled' : null,
              (selectBookingType === 'Adventure Golf'
                || bookingInputForm[bookingInputForm.type.key].value === 'Adventure Golf'
              ) ? 'is-adventure' : null
            ]"
          >
            <vue-timepicker
              :id="`input-${bookingInputForm.timeTo.key}`"
              ref="timeToRef"
              v-model="bookingInputForm[bookingInputForm.timeTo.key].value"
              :hour-range="timeToHourRange"
              :minute-interval="timeToMinuteInterval"
              :minute-range="timeToMinuteRange"
              hide-disabled-hours
              hide-disabled-minutes
              @input="onInputTimeTo"
            />
          </b-form-group>
        </b-col>
        <template v-if="selectBookingType === 'Adventure Golf' || bookingInputForm[bookingInputForm.type.key].value === 'Adventure Golf'">
          <b-col md="4">
            <b-form-group
              label-for="input-ERWACHSENE"
              class="type-select"
            >
              <template #label>
                <div class="d-flex justify-content-between">
                  <span>ERWACHSENE</span>
                  <button
                    class="text-underlined"
                    @click="adventureBookingSelectQty['ERWACHSENE'] = 0"
                  >
                    <u><small>Clear</small></u>
                  </button>
                </div>
              </template>
              <b-input
                id="input-ERWACHSENE"
                v-model="adventureBookingSelectQty['ERWACHSENE']"
                placeholder="Betrag auswählen"
                type="number"
                min="0"
                max="999"
                @change="onInputQtyErw"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label-for="input-KINDER"
              class="type-select"
            >
              <template #label>
                <div class="d-flex justify-content-between">
                  <span>KINDER</span>
                  <button
                    class="text-underlined"
                    @click="adventureBookingSelectQty['KINDER'] = 0"
                  >
                    <u><small>Clear</small></u>
                  </button>
                </div>
              </template>
              <b-form-select
                id="input-KINDER"
                v-model="adventureBookingSelectQty['KINDER']"
              >
                <template #first>
                  <b-form-select-option
                    :value="0"
                    disabled
                  >
                    -- Betrag auswählen --
                  </b-form-select-option>
                </template>
                <b-form-select-option
                  v-for="i in adventureGolfMaxTotalQuantity"
                  :key="`select-kinder-${i}`"
                  :value="i"
                >
                  {{ i }}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label-for="input-FAMILIE"
              class="type-select"
            >
              <template #label>
                <div class="d-flex justify-content-between">
                  <span>FAMILIE</span>
                  <button
                    class="text-underlined"
                    @click="adventureBookingSelectQty['FAMILIE'] = 0"
                  >
                    <u><small>Clear</small></u>
                  </button>
                </div>
              </template>
              <b-form-select
                id="input-FAMILIE"
                v-model="adventureBookingSelectQty['FAMILIE']"
              >
                <template #first>
                  <b-form-select-option
                    :value="0"
                    disabled
                  >
                    -- Betrag auswählen --
                  </b-form-select-option>
                </template>
                <b-form-select-option
                  :value="1"
                  :disabled="adventureGolfTotalSelectQty > 1"
                >
                  1
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col
            v-if="isErrorAdventureGolfQty"
            md="12"
          >
            <p
              :style="{
                color: '#D51030',
                fontSize: '12px'
              }"
            >
              Please add at least 1 ERWACHSENE, KINDER, or FAMILIE
            </p>
          </b-col>
        </template>
        <b-col
          v-if="bookingInputForm.type.value === 'News'"
          md="12"
        >
          <b-form-group>
            <b-form-checkbox
              id="input-GanzerTag"
              v-model="is24Hour"
              @input="onClick24Hour"
            >
              Ganzer Tag
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col
          :md="bookingInputForm.information.col"
        >
          <b-form-group
            :label="bookingInputForm.information.label"
            :label-for="`input-${bookingInputForm.information.key}`"
            :state="isErrorBookingForm[bookingInputForm.information.key]"
            :invalid-feedback="errorMsgBookingForm[bookingInputForm.information.key]"
          >
            <b-form-textarea
              :id="`input-${bookingInputForm.information.key}`"
              v-model="bookingInputForm[bookingInputForm.information.key].value"
              rows="5"
              @input="isErrorBookingForm[bookingInputForm.information.key] = null"
            />
          </b-form-group>
        </b-col>
        <template v-if="selectBookingType === 'Adventure Golf' || bookingInputForm[bookingInputForm.type.key].value === 'Adventure Golf'">
          <b-col md="6">
            <b-form-group
              label-for="input-golf-MA"
              label="MA"
              class="type-select"
              :state="isErrorAdventureBookingInputMa"
              :invalid-feedback="errorMsgAdventureBookingInputMa"
            >
              <b-input
                id="input-golf-MA"
                v-model="adventureBookingInputMa"
                placeholder="Mitarbeiter and not Betrag auswählen"
                @change="onInputAdventureGolfMA"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            class="align-self-center"
          >
            <b-form-checkbox
              id="input-golf-invoice"
              v-model="adventureBookingInputInvoice"
              name="input-golf-invoice"
              value="1"
              unchecked-value="0"
            >
              Invoice
            </b-form-checkbox>
          </b-col>
        </template>
        <b-col
          md="12"
          class="mt-4 mb-2"
        >
          <h5>Kundeninformationen</h5>
        </b-col>
        <b-col :md="bookingInputForm.name.col">
          <b-form-group
            :label="bookingInputForm.name.label"
            :label-for="`input-${bookingInputForm.name.key}`"
            :state="isErrorBookingForm[bookingInputForm.name.key]"
            :invalid-feedback="errorMsgBookingForm[bookingInputForm.name.key]"
          >
            <b-form-input
              :id="`input-${bookingInputForm.name.key}`"
              v-model="bookingInputForm[bookingInputForm.name.key].value"
              :type="bookingInputForm[bookingInputForm.name.key].type"
              @input="isErrorBookingForm[bookingInputForm.name.key] = null"
            />
          </b-form-group>
        </b-col>
        <b-col :md="bookingInputForm.company.col">
          <b-form-group
            :label="bookingInputForm.company.label"
            :label-for="`input-${bookingInputForm.company.key}`"
            :state="isErrorBookingForm[bookingInputForm.company.key]"
            :invalid-feedback="errorMsgBookingForm[bookingInputForm.company.key]"
          >
            <b-form-input
              :id="`input-${bookingInputForm.company.key}`"
              v-model="bookingInputForm[bookingInputForm.company.key].value"
              :type="bookingInputForm[bookingInputForm.company.key].type"
              @input="isErrorBookingForm[bookingInputForm.company.key] = null"
            />
          </b-form-group>
        </b-col>
        <b-col :md="bookingInputForm.telephone.col">
          <b-form-group
            :label="bookingInputForm.telephone.label"
            :label-for="`input-${bookingInputForm.telephone.key}`"
            :state="isErrorBookingForm[bookingInputForm.telephone.key]"
            :invalid-feedback="errorMsgBookingForm[bookingInputForm.telephone.key]"
          >
            <b-form-input
              :id="`input-${bookingInputForm.telephone.key}`"
              v-model="bookingInputForm[bookingInputForm.telephone.key].value"
              :type="bookingInputForm[bookingInputForm.telephone.key].type"
              @input="isErrorBookingForm[bookingInputForm.telephone.key] = null"
            />
          </b-form-group>
        </b-col>
        <b-col :md="bookingInputForm.email.col">
          <b-form-group
            :label="bookingInputForm.email.label"
            :label-for="`input-${bookingInputForm.email.key}`"
            :state="isErrorBookingForm[bookingInputForm.email.key]"
            :invalid-feedback="errorMsgBookingForm[bookingInputForm.email.key]"
          >
            <b-form-input
              :id="`input-${bookingInputForm.email.key}`"
              v-model="bookingInputForm[bookingInputForm.email.key].value"
              :type="bookingInputForm[bookingInputForm.email.key].type"
              @input="isErrorBookingForm[bookingInputForm.email.key] = null"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-btn
        variant="tab-orange"
        class="mt-1"
        @click="onSubmit"
      >
        Speichern
      </b-btn>
    </base-modal>

    <base-modal
      id="admin-edit-event-modal"
      title="Termin bearbeiten"
      custom-class="admin-create-event-modal"
      size="lg"
      admin
      no-close-on-backdrop
      @hide="onHideEditModal"
    >
      <b-row class="booking-form">
        <b-col :md="bookingInputForm.date.col">
          <b-form-group
            :label="bookingInputForm.date.label"
            :label-for="`input-${bookingInputForm.date.key}`"
            label-cols-sm="3"
            :state="isErrorBookingForm[bookingInputForm.date.key]"
            :invalid-feedback="errorMsgBookingForm[bookingInputForm.date.key]"
          >
            <b-form-datepicker
              :id="`input-${bookingInputForm.date.key}`"
              v-model="bookingInputForm[bookingInputForm.date.key].value"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              :min="today"
              locale="de"
              @input="onInputDateBookingForm"
            />
          </b-form-group>
        </b-col>
        <b-col :md="bookingInputForm.type.col">
          <b-form-group
            :label="bookingInputForm.type.label"
            :label-for="`input-${bookingInputForm.type.key}`"
            label-cols-sm="3"
            :state="isErrorBookingForm[bookingInputForm.type.key]"
            :invalid-feedback="errorMsgBookingForm[bookingInputForm.type.key]"
          >
            <b-form-select
              :id="`input-${bookingInputForm.type.key}`"
              v-model="bookingInputForm[bookingInputForm.type.key].value"
              :options="typeOptions"
              @input="isErrorBookingForm[bookingInputForm.type.key] = null"
            />
          </b-form-group>
        </b-col>
        <b-col :md="bookingInputForm.timeFrom.col">
          <b-form-group
            :label="bookingInputForm.timeFrom.label"
            :label-for="`input-${bookingInputForm.timeFrom.key}`"
            label-cols-sm="3"
            :state="isErrorBookingForm[bookingInputForm.timeFrom.key]"
            :invalid-feedback="errorMsgBookingForm[bookingInputForm.timeFrom.key]"
          >
            <vue-timepicker
              :id="`input-${bookingInputForm.timeFrom.key}`"
              v-model="bookingInputForm[bookingInputForm.timeFrom.key].value"
              :hour-range="timeFromHourRange"
              :minute-interval="timeFromMinuteInterval"
              hide-disabled-hours
              @input="onInputTimeFrom"
            />
          </b-form-group>
        </b-col>
        <b-col :md="bookingInputForm.timeTo.col">
          <b-form-group
            :label="bookingInputForm.timeTo.label"
            :label-for="`input-${bookingInputForm.timeTo.key}`"
            label-cols-sm="3"
            :state="isErrorBookingForm[bookingInputForm.timeTo.key]"
            :invalid-feedback="errorMsgBookingForm[bookingInputForm.timeTo.key]"
            :class="[
              (isHideDateNews
                || isTimeFromIncomplete
              ) ? 'disabled' : null,
              (selectBookingType === 'Adventure Golf'
                || bookingInputForm[bookingInputForm.type.key].value === 'Adventure Golf'
              ) ? 'is-adventure' : null
            ]"
          >
            <vue-timepicker
              :id="`input-${bookingInputForm.timeTo.key}`"
              v-model="bookingInputForm[bookingInputForm.timeTo.key].value"
              :hour-range="timeToHourRange"
              :minute-interval="timeToMinuteInterval"
              :minute-range="timeToMinuteRange"
              hide-disabled-hours
              hide-disabled-minutes
              @input="onInputTimeTo"
            />
          </b-form-group>
        </b-col>
        <template v-if="selectBookingType === 'Adventure Golf' || bookingInputForm[bookingInputForm.type.key].value === 'Adventure Golf'">
          <b-col md="4">
            <b-form-group
              label-for="input-ERWACHSENE"
              class="type-select"
            >
              <template #label>
                <div class="d-flex justify-content-between">
                  <span>ERWACHSENE</span>
                  <button
                    class="text-underlined"
                    @click="adventureBookingSelectQty['ERWACHSENE'] = 0"
                  >
                    <u><small>Clear</small></u>
                  </button>
                </div>
              </template>
              <b-input
                id="input-ERWACHSENE"
                v-model="adventureBookingSelectQty['ERWACHSENE']"
                placeholder="Betrag auswählen"
                type="number"
                min="0"
                max="999"
                @change="onInputQtyErw"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label-for="input-KINDER"
              class="type-select"
            >
              <template #label>
                <div class="d-flex justify-content-between">
                  <span>KINDER</span>
                  <button
                    class="text-underlined"
                    @click="adventureBookingSelectQty['KINDER'] = 0"
                  >
                    <u><small>Clear</small></u>
                  </button>
                </div>
              </template>
              <b-form-select
                id="input-KINDER"
                v-model="adventureBookingSelectQty['KINDER']"
              >
                <template #first>
                  <b-form-select-option
                    :value="0"
                    disabled
                  >
                    -- Betrag auswählen --
                  </b-form-select-option>
                </template>
                <b-form-select-option
                  v-for="i in adventureGolfMaxTotalQuantity"
                  :key="`select-kinder-${i}`"
                  :value="i"
                >
                  {{ i }}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label-for="input-FAMILIE"
              class="type-select"
            >
              <template #label>
                <div class="d-flex justify-content-between">
                  <span>FAMILIE</span>
                  <button
                    class="text-underlined"
                    @click="adventureBookingSelectQty['FAMILIE'] = 0"
                  >
                    <u><small>Clear</small></u>
                  </button>
                </div>
              </template>
              <b-form-select
                id="input-FAMILIE"
                v-model="adventureBookingSelectQty['FAMILIE']"
              >
                <template #first>
                  <b-form-select-option
                    :value="0"
                    disabled
                  >
                    -- Betrag auswählen --
                  </b-form-select-option>
                </template>
                <b-form-select-option
                  :value="1"
                  :disabled="adventureGolfTotalSelectQty > 1"
                >
                  1
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col
            v-if="isErrorAdventureGolfQty"
            md="12"
          >
            <p
              :style="{
                color: '#D51030',
                fontSize: '12px'
              }"
            >
              Please add at least 1 ERWACHSENE, KINDER, or FAMILIE
            </p>
          </b-col>
        </template>
        <b-col :md="bookingInputForm.information.col">
          <b-form-group
            :label="bookingInputForm.information.label"
            :label-for="`input-${bookingInputForm.information.key}`"
            :state="isErrorBookingForm[bookingInputForm.information.key]"
            :invalid-feedback="errorMsgBookingForm[bookingInputForm.information.key]"
          >
            <b-form-textarea
              :id="`input-${bookingInputForm.information.key}`"
              v-model="bookingInputForm[bookingInputForm.information.key].value"
              rows="5"
              @input="isErrorBookingForm[bookingInputForm.information.key] = null"
            />
          </b-form-group>
        </b-col>
        <template v-if="selectBookingType === 'Adventure Golf' || bookingInputForm[bookingInputForm.type.key].value === 'Adventure Golf'">
          <b-col md="6">
            <b-form-group
              label-for="input-golf-MA"
              label="MA"
              class="type-select"
              :state="isErrorAdventureBookingInputMa"
              :invalid-feedback="errorMsgAdventureBookingInputMa"
            >
              <b-input
                id="input-golf-MA"
                v-model="adventureBookingInputMa"
                placeholder="Mitarbeiter and not Betrag auswählen"
                @change="onInputAdventureGolfMA"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            class="align-self-center"
          >
            <b-form-checkbox
              id="input-golf-invoice"
              v-model="adventureBookingInputInvoice"
              name="input-golf-invoice"
              value="1"
              unchecked-value="0"
            >
              Invoice
            </b-form-checkbox>
          </b-col>
        </template>
        <b-col
          md="12"
          class="mt-4 mb-2"
        >
          <h5>Kundeninformationen</h5>
        </b-col>
        <b-col :md="bookingInputForm.name.col">
          <b-form-group
            :label="bookingInputForm.name.label"
            :label-for="`input-${bookingInputForm.name.key}`"
            :state="isErrorBookingForm[bookingInputForm.name.key]"
            :invalid-feedback="errorMsgBookingForm[bookingInputForm.name.key]"
          >
            <b-form-input
              :id="`input-${bookingInputForm.name.key}`"
              v-model="bookingInputForm[bookingInputForm.name.key].value"
              :type="bookingInputForm[bookingInputForm.name.key].type"
              @input="isErrorBookingForm[bookingInputForm.name.key] = null"
            />
          </b-form-group>
        </b-col>
        <b-col :md="bookingInputForm.company.col">
          <b-form-group
            :label="bookingInputForm.company.label"
            :label-for="`input-${bookingInputForm.company.key}`"
            :state="isErrorBookingForm[bookingInputForm.company.key]"
            :invalid-feedback="errorMsgBookingForm[bookingInputForm.company.key]"
          >
            <b-form-input
              :id="`input-${bookingInputForm.company.key}`"
              v-model="bookingInputForm[bookingInputForm.company.key].value"
              :type="bookingInputForm[bookingInputForm.company.key].type"
              @input="isErrorBookingForm[bookingInputForm.company.key] = null"
            />
          </b-form-group>
        </b-col>
        <b-col :md="bookingInputForm.telephone.col">
          <b-form-group
            :label="bookingInputForm.telephone.label"
            :label-for="`input-${bookingInputForm.telephone.key}`"
            :state="isErrorBookingForm[bookingInputForm.telephone.key]"
            :invalid-feedback="errorMsgBookingForm[bookingInputForm.telephone.key]"
          >
            <b-form-input
              :id="`input-${bookingInputForm.telephone.key}`"
              v-model="bookingInputForm[bookingInputForm.telephone.key].value"
              :type="bookingInputForm[bookingInputForm.telephone.key].type"
              @input="isErrorBookingForm[bookingInputForm.telephone.key] = null"
            />
          </b-form-group>
        </b-col>
        <b-col :md="bookingInputForm.email.col">
          <b-form-group
            :label="bookingInputForm.email.label"
            :label-for="`input-${bookingInputForm.email.key}`"
            :state="isErrorBookingForm[bookingInputForm.email.key]"
            :invalid-feedback="errorMsgBookingForm[bookingInputForm.email.key]"
          >
            <b-form-input
              :id="`input-${bookingInputForm.email.key}`"
              v-model="bookingInputForm[bookingInputForm.email.key].value"
              :type="bookingInputForm[bookingInputForm.email.key].type"
              @input="isErrorBookingForm[bookingInputForm.email.key] = null"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-btn
        variant="tab-orange"
        class="mt-1"
        @click="onSubmitEdit"
      >
        Speichern
      </b-btn>
    </base-modal>

    <base-modal
      id="admin-duplicate-event-modal"
      title="Replace or Cancel"
      custom-class="admin-duplicate-event-modal"
      size="lg"
      admin
      no-close-on-backdrop
      @hide="onCancelDuplicateEvent"
    >
      <div class="duplicate-event-list">
        <div
          v-for="(event, index) in duplicateEvents"
          :key="`duplicate-${event.identification}${index}`"
          class="duplicate-event-item"
        >
          <p class="mb-1">
            <strong>Type: {{ event.activityName }}</strong>
          </p>
          <p>
            {{ `Booker: ${event.name || 'Admin'}` }}
          </p>
          <p>
            <span>{{ event.bookingDay | germanCompleteDateTime }}</span>
          </p>
          <p>
            <strong>Time:</strong>
            {{ `${event.timeFrom} - ${event.timeTo}` }}
          </p>
          <p>
            <strong>Your new selected:</strong>
            <span class="text-danger ml-2">Type: {{ bookingInputForm.type.value }}, </span>
            <span class="text-danger ml-2">{{ `${bookingInputForm.timeFrom.value} - ${bookingInputForm.timeTo.value}` }}</span>
          </p>
          <template v-if="event.information">
            <p>Information:</p>
            <p v-html="event.information" />
          </template>
        </div>
      </div>
      <div
        v-if="duplicateEvents.length > 0"
        class="duplicate-event-functions"
      >
        <b-btn
          size="sm"
          class="item"
          @click="onSubmitAfterHandleDuplicate"
        >
          Replace
        </b-btn>
        <b-btn
          size="sm"
          class="mt-1"
          @click="onCancelDuplicateEvent"
        >
          Cancel
        </b-btn>
      </div>
    </base-modal>

    <base-modal
      id="admin-booking-detail-modal"
      title="Buchungsdetails"
      custom-class="admin-booking-detail-modal"
      size="xl"
      admin
    >
      <template v-if="selectBookingDetail.bookingDay">
        <b-row>
          <b-col
            lg="6"
            md="6"
          >
            <h5 class="mb-4 text-center">
              Buchungsdetails
            </h5>
            <p class="mb-3">
              <strong class="row-label">Datum:</strong> {{ selectBookingDetail.bookingDay | germanCompleteDateTime }}
            </p>
            <p class="mb-3">
              <strong class="row-label">Uhrzeit:</strong> {{ `${selectBookingDetail.timeFrom.slice(0, -3)} - ${selectBookingDetail.timeTo.slice(0, -3)}` }}
            </p>
            <p class="mb-3">
              <strong class="row-label">Terminart:</strong> {{ selectBookingDetail.activityName }}
            </p>
            <p
              v-if="selectBookingDetail.teamSize"
              class="mb-3"
            >
              <strong class="row-label">Team size:</strong> {{ getAdventureGolfTeamSizeSum(selectBookingDetail.teamSize) }}
            </p>
            <div
              v-if="selectBookingDetail.teamSize"
              class="d-flex mb-3"
            >
              <strong class="row-label">Ticket:</strong>
              <div v-html="getAdventureGolfTeamTicketAmount(selectBookingDetail.teamSize)" />
            </div>
            <p v-if="selectBookingDetail.teamSize">
              <strong class="row-label">Price:</strong> {{ getAdventureGolfPriceSum(selectBookingDetail.teamSize) }}
            </p>
            <p
              v-if="selectBookingDetail.maName"
              class="mt-3"
            >
              <strong class="row-label">MA:</strong> {{ selectBookingDetail.maName }}
            </p>
            <p
              v-if="selectBookingDetail.activityName === 'Adventure Golf' && (selectBookingDetail.invoice !== null || selectBookingDetail.invoice !== undefined)"
              class="mt-3"
            >
              <strong class="row-label">Invoice:</strong> {{ selectBookingDetail.invoice }}
            </p>
          </b-col>
          <b-col
            lg="6"
            md="6"
          >
            <h5 class="mb-4 text-center">
              Kundendetails
            </h5>
            <p class="mb-3">
              <strong class="row-label">Name:</strong> {{ selectBookingDetail.name }}
            </p>
            <p class="mb-3">
              <strong class="row-label">Firma:</strong> {{ selectBookingDetail.company }}
            </p>
            <p class="mb-3">
              <strong class="row-label">Tel:</strong> {{ selectBookingDetail.telephone }}
            </p>
            <p class="mb-3">
              <strong class="row-label">Email:</strong> <a :href="`mailto:${selectBookingDetail.email}`">{{ selectBookingDetail.email }}</a>
            </p>
          </b-col>
        </b-row>
      </template>
    </base-modal>
  </div>
</template>

<style lang="scss">
@import "@/styles/pages/admin-booking.scss";
</style>

<script>
require('@/plugins/datejs/date-de-DE');
import {
  initInputForm,
  arenaTableFields,
  golfTableFields,
  golfGameTableFields,
  adventureGolfTableFields,
  initErrorForm,
  initErrorMsg,
} from './config';

import BookingTable from './components/BookingTable';

import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';
import _ from 'lodash';

import { germanDate, germanCompleteDateTime } from '@/helpers/filters/germanDate';
import { mapState, mapGetters } from 'vuex';
import { AdminInformation } from '@/services';

import { loader } from '@/plugins/loader';

const golfPrice = {
  '0': {
    label: 'ERWACHSENE',
    price: 7.50,
  },
  '1': {
    label: 'KINDER',
    price: 5.50,
  },
  '2': {
    label: 'FAMILIE',
    price: 20.00,
  },
  '3': {
    label: 'ERMÄSSIGTE',
    price: 6.00,
  },
};

export default {
  components: {
    BookingTable,
    VueTimepicker,
  },
  filters: {
    germanDate,
    germanCompleteDateTime,
  },
  data() {
    return {
      today: '',
      selectDate: '',
      filter: '',
      items: [],
      isLoading: false,
      bookingInputForm: initInputForm,
      adventureGolfMaxTotalQuantity: 20,
      adventureBookingSelectQty: {
        'ERWACHSENE': null,
        'KINDER': 0,
        'FAMILIE': 0,
      },
      adventureBookingInputMa: '',
      adventureBookingInputInvoice: '0',
      isErrorAdventureBookingInputMa: null,
      errorMsgAdventureBookingInputMa: '',
      isErrorAdventureGolfQty: false,
      isErrorBookingForm: initErrorForm,
      errorMsgBookingForm: initErrorMsg,

      editId: 0,
      isHideDateNews: false,
      duplicateEvents: [],
      replaceEvents: [],
      selectBookingDetail: {},
      onCreateSelectDate: '',
      cntFetchTime: 0,
      is24Hour: false,
      selectBookingType: '',
      tabIndex: 0,
    };
  },
  computed: {
    ...mapState('admin', ['events', 'notices']),
    ...mapState('base', ['activities']),
    ...mapGetters('base', ['getArenaActivitiesName']),
    isBookingFormError() {
      const hasErrorForm = Object.values(this.isErrorBookingForm).some((val) => val === false);
      if (this.selectBookingType === 'Adventure Golf' || this.bookingInputForm.type.value === 'Adventure Golf') {
        return this.isErrorAdventureGolfQty || hasErrorForm || (this.isErrorAdventureBookingInputMa === false);
      }
      return hasErrorForm;
    },
    typeOptions() {
      const activityNames = this.activities.slice().map((item) => item.activityName);
      if (this.selectBookingType === 'all') {
        return [...activityNames];
      } else if (this.selectBookingType === 'Adventure Golf') {
        return ['Adventure Golf'];
      } else if (this.selectBookingType === '' || this.selectBookingType === 'Arena') {
        return activityNames.slice(0, 6);
      } else if (this.selectBookingType === 'Golf & SkillCourt') {
        return ['Golf Room', 'SkillCourt'];
      }
      return activityNames.filter((item) => item === this.selectBookingType);
    },
    timeFromHour() {
      if (!this.bookingInputForm['timeFrom'].value.split(':')[0]) {
        if (this.bookingInputForm.type.value === 'Adventure Golf') {
          return 11;
        } else {
          return 7;
        }
      }
      return parseInt(this.bookingInputForm['timeFrom'].value.split(':')[0]);
    },
    timeFromHourRange() {
      if (this.bookingInputForm.type.value === 'News') {
        return [[0, 24]];
      }
      if (this.bookingInputForm.type.value === 'Adventure Golf') {
        return [[7, 21]];
      }
      return [[7, 23]];
    },
    timeToHourRange() {
      if (this.bookingInputForm.type.value === 'News') {
        return [[this.timeFromHour, 24]];
      }
      if (this.bookingInputForm.type.value === 'Adventure Golf') {
        return [[this.timeFromHour, 23]];
      }
      return [[this.timeFromHour, 23]];
    },
    timeFromMinuteInterval() {
      if (this.bookingInputForm.type.value === 'News') {
        return '';
      } else if (this.bookingInputForm.type.value === 'Adventure Golf') {
        if (this.bookingInputForm.timeFrom.value === 21) {
          return [0, 15, 30];
        } else {
          return 15;
        }
      }
      return 30;
    },
    timeToMinuteInterval() {
      if (this.bookingInputForm.type.value === 'News') {
        return '';
      } else if (this.bookingInputForm.type.value === 'Adventure Golf') {
        return 15;
      } else if (this.bookingInputForm.type.value === 'Golf Room') {
        return '';
      }
      return 30;
    },
    timeToMinuteRange() {
      if (this.bookingInputForm.type.value === 'Golf Room') {
        return [0, 25, 30, 55];
      }
      return null;
    },
    isTimeFromIncomplete() {
      if (!/^([0-9][0-9]:[0-9][0-9])$/.test(this.bookingInputForm.timeFrom.value) || this.bookingInputForm.timeFrom.value === '') {
        return true;
      }
      return false;
    },
    adventureGolfTotalSelectQty () {
      let quantity = 0;
      Object.keys(this.adventureBookingSelectQty).forEach((key) => {
        quantity += key === 'FAMILIE'
          ? this.adventureBookingSelectQty[key] * 4
          : this.adventureBookingSelectQty[key];
      });
      return quantity;
    },
    arenaTableItems() {
      const allowItems = [
        ...this.getArenaActivitiesName,
        'News',
        'Ferien Camp',
      ];
      return this.items.filter((item) => allowItems.includes(item.activityName));
    },
    golfTableItems() {
      return this.items.filter((item) => item.activityName === 'Golf Room');
    },
    gameTableItems() {
      return this.items.filter((item) => item.activityName === 'SkillCourt');
    },
    golfGameTableItems() {
      return this.items.filter((item) => item.activityName === 'SkillCourt' || item.activityName === 'Golf Room');
    },
    adventureGolfItems() {
      return this.items.filter((item) => item.activityName === 'Adventure Golf');
    },
    arenaTableFields() {
      return arenaTableFields;
    },
    golfGameTableFields() {
      return golfGameTableFields;
    },
    golfTableFields() {
      return golfTableFields;
    },
    adventureGolfTableFields() {
      return adventureGolfTableFields;
    },
  },
  watch: {
    events(val) {
      this.items = val.slice().filter((item) => {
        if (this.isPast(`${item.bookingDay} ${item.timeFrom}`)) {
          return true;
        }
        return item.displayStatus === 1;
      });
    },
    cntFetchTime(val) {
      if (val > 0) {
        this.isLoading = true;
      } else {
        this.isLoading = false;
      }
    },
    tabIndex(val, prevVal) {
      if (val === 0 || prevVal === 0) {
        this.loadEventTable();
      }

      setTimeout(() => {
        if (prevVal === 0 || val !== 0) {
          this.setCalendarElement();
        }
      }, 200);

    },
  },
  created() {
    // Today
    this.selectDate = Date.today().toString('yyyy-MM-dd');
    this.today = Date.today();
    this.loadEventTable();
  },
  mounted() {
    if (this.tabIndex === 0) {
      return;
    }
    this.setCalendarElement();
  },
  methods: {
    onInputAdventureGolfMA() {
      this.adventureBookingInputMa = this.adventureBookingInputMa.replace(/\s/g, '');
      if (this.adventureBookingInputMa.length < 2) {
        this.$bvModal.msgBoxOk('Sorry. You have to input 2 characters', {
          title: 'Warning. Require 2 characters',
          okVariant: 'tab-orange',
          headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
          footerClass: 'p-2 border-top-0',
          centered: true,
        });
      }
      if (this.adventureBookingInputMa.length > 2) {
        this.$bvModal.msgBoxOk('Sorry. You can input only 2 characters', {
          title: 'Max character = 2',
          okVariant: 'tab-orange',
          headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
          footerClass: 'p-2 border-top-0',
          centered: true,
        }).then(() => {
          this.adventureBookingInputMa = this.adventureBookingInputMa.substring(0, 2);
        });
      }
    },
    onInputQtyErw() {
      const qty = this.adventureBookingSelectQty['ERWACHSENE'] || 0;
      if (qty > 999) {
        this.$bvModal.msgBoxOk('Sorry. Your input exceed the max value 999', {
          title: 'Error. Exceed Max Quantity.',
          okVariant: 'tab-orange',
          headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
          footerClass: 'p-2 border-top-0',
          centered: true,
        }).then(() => {
          this.adventureBookingSelectQty['ERWACHSENE'] = 999;
        });
      }
    },
    async onCreateEvent(type) {
      AdminInformation.tokenValid();
      this.resetForm();
      this.selectBookingType = type;
      if (type === 'Adventure Golf') {
        const yearMonth = Date.today().toString('yyyy-MM');
        const response = await AdminInformation.checkCloseDate(yearMonth);
        if (response.status === 200 && response.data.includes(this.selectDate)) {
          this.$bvModal.msgBoxOk('This is just a Warnung. Your select date is closed.', {
            title: 'Warnung',
            okVariant: 'tab-orange',
            headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
            footerClass: 'p-2 border-top-0',
            centered: true,
          }).then(() => {
            const splitDates = this.selectDate.split('-');
            const dateSlashFormat = `${splitDates[0]}/${parseInt(splitDates[2])}/${parseInt(splitDates[1])}`; // month/day/year - 7/1/2004
            this.bookingInputForm.date.value = Date.parse(dateSlashFormat);
            this.onCreateSelectDate = Date.parse(dateSlashFormat);
            this.bookingInputForm = {
              ...this.bookingInputForm,
              type: {
                ...this.bookingInputForm.type,
                value: this.typeOptions[0],
              },
            };
            this.$bvModal.show('admin-create-event-modal');
          });
        } else {
          const splitDates = this.selectDate.split('-');
          const dateSlashFormat = `${splitDates[0]}/${parseInt(splitDates[2])}/${parseInt(splitDates[1])}`; // month/day/year - 7/1/2004
          this.bookingInputForm.date.value = Date.parse(dateSlashFormat);
          this.onCreateSelectDate = Date.parse(dateSlashFormat);
          this.bookingInputForm = {
            ...this.bookingInputForm,
            type: {
              ...this.bookingInputForm.type,
              value: this.typeOptions[0],
            },
          };
          this.$bvModal.show('admin-create-event-modal');
        }
        return;
      }
      const splitDates = this.selectDate.split('-');
      const dateSlashFormat = `${splitDates[0]}/${parseInt(splitDates[2])}/${parseInt(splitDates[1])}`; // month/day/year - 7/1/2004
      this.bookingInputForm.date.value = Date.parse(dateSlashFormat);
      this.onCreateSelectDate = Date.parse(dateSlashFormat);
      this.bookingInputForm = {
        ...this.bookingInputForm,
        type: {
          ...this.bookingInputForm.type,
          value: this.typeOptions[0],
        },
      };
      this.$bvModal.show('admin-create-event-modal');
    },
    onEdit(item) {
      AdminInformation.tokenValid();
      this.selectBookingType = item.activityName;
      this.resetForm();
      const splitDates = item.bookingDay.split('-');
      const dateSlashFormat = `${splitDates[0]}/${parseInt(splitDates[2])}/${parseInt(splitDates[1])}`; // month/day/year - 7/1/2004
      this.onCreateSelectDate = Date.parse(dateSlashFormat);
      this.editId = item.identification;
      Object.keys(this.bookingInputForm).forEach((itemKey) => {
        this.bookingInputForm = {
          ...this.bookingInputForm,
          [itemKey]: {
            ...this.bookingInputForm[itemKey],
            value: item[itemKey],
          },
        };
      });

      this.bookingInputForm = {
        ...this.bookingInputForm,
        ['date']: {
          ...this.bookingInputForm['date'],
          value: item['bookingDay'],
        },
        ['type']: {
          ...this.bookingInputForm['type'],
          value: item['activityName'],
        },
      };

      if (this.typeOptions.length === 1) {
        this.bookingInputForm = {
          ...this.bookingInputForm,
          type: {
            ...this.bookingInputForm.type,
            value: this.typeOptions[0],
          },
        };
      }
      if (item.teamSize) {
        const teamSizeSplits = item.teamSize.split('|');
        this.adventureBookingSelectQty = {
          'ERWACHSENE': teamSizeSplits[0],
          'KINDER': teamSizeSplits[1],
          'FAMILIE': teamSizeSplits[2],
        };
      }

      if (item.maName) {
        this.adventureBookingInputMa = item.maName;
      }
      if (item.invoice) {
        this.adventureBookingInputInvoice = item.invoice;
      }
      this.$bvModal.show('admin-edit-event-modal');
    },
    onDelete(id) {
      this.$bvModal.msgBoxConfirm('Bist Du sicher? Das Löschen kann nicht rückgängig gemacht werden.', {
        title: 'Termin löschen',
        okVariant: 'tab-orange',
        headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
        footerClass: 'p-2 border-top-0',
        centered: true,
        cancelTitle: 'Abbrechen',
      })
        .then(async (val) => {
          if (!val) {
            return;
          }
          loader.start();
          try {
            await this.$store.dispatch('admin/cancelBooking', id);
            this.$bvModal.msgBoxOk('Booking has been deleted successfully', {
              title: 'Delete successful',
              okVariant: 'tab-orange',
              headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
              footerClass: 'p-2 border-top-0',
              centered: true,
            });
            loader.stop();
          } catch (err) {
            loader.stop();
            this.$bvModal.msgBoxOk(err, {
              title: 'Delete Error',
              okVariant: 'tab-orange',
              headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
              footerClass: 'p-2 border-top-0',
              centered: true,
            });
          }
        });
    },
    async onInputTimeFrom() {
      this.isErrorBookingForm = {
        ...this.isErrorBookingForm,
        timeTo: null,
        timeFrom: null,
      };
      this.errorMsgBookingForm = {
        ...this.errorMsgBookingForm,
        timeTo: '',
        timeFrom: '',
      };
      if (this.bookingInputForm.timeFrom.value === '') {
        this.bookingInputForm = {
          ...this.bookingInputForm,
          timeTo: {
            ...this.bookingInputForm.timeTo,
            value: '',
          },
        };
        return;
      }
      if (this.selectBookingType === 'Adventure Golf' || this.bookingInputForm.type.value === 'Adventure Golf') {
        if (this.bookingInputForm.timeFrom.value === '21:45') {
          this.bookingInputForm = {
            ...this.bookingInputForm,
            timeFrom: {
              ...this.bookingInputForm.timeFrom,
              value: '',
            },
            timeTo: {
              ...this.bookingInputForm.timeTo,
              value: '',
            },
          };
          this.$refs.timeFromRef.clearTime();
          this.$refs.timeToRef.clearTime();
        } else if (this.bookingInputForm.timeFrom.value !== '' && this.bookingInputForm.timeFrom.value.split(':')[1] !== 'mm') {
          const today = Date.today().toString('dd/MM/yyyy');
          this.bookingInputForm = {
            ...this.bookingInputForm,
            timeTo: {
              ...this.bookingInputForm.timeTo,
              value: Date.parse(`${today} ${this.bookingInputForm.timeFrom.value}:00`).addMinutes(90).toString('HH:mm'),
            },
          };
        }
      }
    },
    onInputTimeTo() {
      this.isErrorBookingForm = {
        ...this.isErrorBookingForm,
        timeTo: null,
        timeFrom: null,
      };
      this.errorMsgBookingForm = {
        ...this.errorMsgBookingForm,
        timeTo: '',
        timeFrom: '',
      };
    },
    validateInput() {
      if (
        this.selectBookingType === 'Golf & SkillCourt'
                || this.selectBookingType === 'SkillCourt'
                || this.selectBookingType === 'Golf Room'
                || this.bookingInputForm.type.value === 'Golf Room'
                || this.bookingInputForm.type.value === 'SkillCourt'
      ) {
        Object.values(this.bookingInputForm).forEach((item) => {
          if (item.required && item.value === '') {
            this.isErrorBookingForm = {
              ...this.isErrorBookingForm,
              [item.key]: false,
            };
            this.errorMsgBookingForm = {
              ...this.errorMsgBookingForm,
              [item.key]: 'Dieses Feld wird benötigt',
            };
          } else if (item.value !== '' && item.type === 'time') {
            const today = Date.today().toString('dd/MM/yyyy');
            if (this.bookingInputForm.timeFrom.value === this.bookingInputForm.timeTo.value) {
              this.isErrorBookingForm = {
                ...this.isErrorBookingForm,
                ['timeTo']: false,
              };
              this.errorMsgBookingForm = {
                ...this.errorMsgBookingForm,
                ['timeTo']: 'Mindestzeitraum 1 Stunde',
              };
            } else if (Date.parse(`${today} ${this.bookingInputForm.timeTo.value}:00`) < Date.parse(`${today} ${this.bookingInputForm.timeFrom.value}:00`)) {
              this.isErrorBookingForm = {
                ...this.isErrorBookingForm,
                ['timeFrom']: false,
              };
              this.errorMsgBookingForm = {
                ...this.errorMsgBookingForm,
                ['timeFrom']: 'Mindestzeitraum 1 Stunde',
              };
            }
          } else {
            this.isErrorBookingForm = {
              ...this.isErrorBookingForm,
              [item.key]: null,
            };
            this.errorMsgBookingForm = {
              ...this.errorMsgBookingForm,
              [item.key]: '',
            };
          }
        });
        return;
      }

      if (this.selectBookingType === 'Adventure Golf' || this.bookingInputForm.type.value === 'Adventure Golf') {
        Object.keys(this.bookingInputForm).forEach((key) => {
          const item = this.bookingInputForm[key];
          if ((item.required && item.value === '')
                        || (key === 'email' && item.value === '')
                        || (key === 'telephone' && item.value === '')
          ) {
            this.isErrorBookingForm = {
              ...this.isErrorBookingForm,
              [item.key]: false,
            };
            this.errorMsgBookingForm = {
              ...this.errorMsgBookingForm,
              [item.key]: 'Dieses Feld wird benötigt',
            };
          } else if (item.value !== '' && item.type === 'time') {
            const today = Date.today().toString('dd/MM/yyyy');
            const isArenaType = this.getArenaActivitiesName.includes(this.bookingInputForm.type.value);

            if (!/^([0-9][0-9]:[0-9][0-9])$/.test(item.value)) {
              this.isErrorBookingForm = {
                ...this.isErrorBookingForm,
                [item.key]: false,
              };
              this.errorMsgBookingForm = {
                ...this.errorMsgBookingForm,
                [item.key]: 'Bitte geben Sie die Endzeit ein',
              };
            } else if (this.bookingInputForm.timeFrom.value === this.bookingInputForm.timeTo.value) {
              this.isErrorBookingForm = {
                ...this.isErrorBookingForm,
                ['timeTo']: false,
              };
              this.errorMsgBookingForm = {
                ...this.errorMsgBookingForm,
                ['timeTo']: 'Bitte buchen Sie mindestens 30 Minuten',
              };
            }  else if (Date.parse(`${today} ${this.bookingInputForm.timeTo.value}:00`) < Date.parse(`${today} ${this.bookingInputForm.timeFrom.value}:00`)) {
              this.isErrorBookingForm = {
                ...this.isErrorBookingForm,
                ['timeFrom']: false,
              };
              this.errorMsgBookingForm = {
                ...this.errorMsgBookingForm,
                ['timeFrom']: 'Bitte wählen Sie die Zeit von vor Zeit bis.',
              };
            } else if (this.bookingInputForm.timeFrom.value === '07:00' && isArenaType) {
              this.isErrorBookingForm = {
                ...this.isErrorBookingForm,
                ['timeFrom']: false,
              };
              this.errorMsgBookingForm = {
                ...this.errorMsgBookingForm,
                ['timeFrom']: 'Bitte wählen Sie die Zeit nach 07.30 Uhr.',
              };
            }
          } else {
            this.isErrorBookingForm = {
              ...this.isErrorBookingForm,
              [item.key]: null,
            };
            this.errorMsgBookingForm = {
              ...this.errorMsgBookingForm,
              [item.key]: '',
            };
          }
        });
        if (this.adventureBookingInputMa.length > 0 && this.adventureBookingInputMa.length !== 2) {
          this.isErrorAdventureBookingInputMa = false;
          this.errorMsgAdventureBookingInputMa = 'Require 2 characters';
        } else {
          this.isErrorAdventureBookingInputMa = null;
          this.errorMsgAdventureBookingInputMa = '';
        }

        const teamSize = `${this.adventureBookingSelectQty['ERWACHSENE'] || 0}|${this.adventureBookingSelectQty['KINDER']}|${this.adventureBookingSelectQty['FAMILIE']}|0`;
        if (this.getAdventureGolfTeamSizeSum(teamSize) === 0) {
          this.isErrorAdventureGolfQty = true;
        } else {
          this.isErrorAdventureGolfQty = false;
        }
        return;
      }
      Object.values(this.bookingInputForm).forEach((item) => {
        if (item.required && item.value === '') {
          this.isErrorBookingForm = {
            ...this.isErrorBookingForm,
            [item.key]: false,
          };
          this.errorMsgBookingForm = {
            ...this.errorMsgBookingForm,
            [item.key]: 'Dieses Feld wird benötigt',
          };
        } else if (item.value !== '' && item.type === 'time') {
          const today = Date.today().toString('dd/MM/yyyy');
          const isArenaType = this.getArenaActivitiesName.includes(this.bookingInputForm.type.value);

          if (!/^([0-9][0-9]:[0-9][0-9])$/.test(item.value)) {
            this.isErrorBookingForm = {
              ...this.isErrorBookingForm,
              [item.key]: false,
            };
            this.errorMsgBookingForm = {
              ...this.errorMsgBookingForm,
              [item.key]: 'Bitte geben Sie die Endzeit ein',
            };
          } else if (this.bookingInputForm.timeFrom.value === this.bookingInputForm.timeTo.value) {
            this.isErrorBookingForm = {
              ...this.isErrorBookingForm,
              ['timeTo']: false,
            };
            this.errorMsgBookingForm = {
              ...this.errorMsgBookingForm,
              ['timeTo']: 'Bitte buchen Sie mindestens 30 Minuten',
            };
          }  else if (Date.parse(`${today} ${this.bookingInputForm.timeTo.value}:00`) < Date.parse(`${today} ${this.bookingInputForm.timeFrom.value}:00`)) {
            this.isErrorBookingForm = {
              ...this.isErrorBookingForm,
              ['timeFrom']: false,
            };
            this.errorMsgBookingForm = {
              ...this.errorMsgBookingForm,
              ['timeFrom']: 'Bitte wählen Sie die Zeit von vor Zeit bis.',
            };
          } else if (this.bookingInputForm.timeFrom.value === '07:00' && isArenaType) {
            this.isErrorBookingForm = {
              ...this.isErrorBookingForm,
              ['timeFrom']: false,
            };
            this.errorMsgBookingForm = {
              ...this.errorMsgBookingForm,
              ['timeFrom']: 'Bitte wählen Sie die Zeit nach 07.30 Uhr.',
            };
          }
        } else {
          this.isErrorBookingForm = {
            ...this.isErrorBookingForm,
            [item.key]: null,
          };
          this.errorMsgBookingForm = {
            ...this.errorMsgBookingForm,
            [item.key]: '',
          };
        }
      });
    },
    onHideDateTime() {
      this.isHideDateNews = !this.isHideDateNews;
      this.bookingInputForm.date.value = '';
      this.bookingInputForm.timeFrom.value = '';
      this.bookingInputForm.timeTo.value = '';

      if (this.isHideDateNews) {
        this.bookingInputForm.date.required = false;
        this.bookingInputForm.timeFrom.required = false;
        this.bookingInputForm.timeTo.required = false;
      } else {
        this.bookingInputForm.date.required = true;
        this.bookingInputForm.timeFrom.required = true;
        this.bookingInputForm.timeTo.required = true;
      }
    },
    async onInputDateBookingForm() {
      this.isErrorBookingForm[this.bookingInputForm.date.key] = null;
      if (this.selectBookingType === 'Adventure Golf') {
        loader.start();
        try {
          const yearMonth = Date.today().toString('yyyy-MM');
          const response = await AdminInformation.checkCloseDate(yearMonth);
          if (response.status === 200 && response.data.includes(this.bookingInputForm.date.value)) {
            this.$bvModal.msgBoxOk('Your select date is closed. Please select other date.', {
              title: 'Date close',
              okVariant: 'tab-orange',
              headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
              footerClass: 'p-2 border-top-0',
              centered: true,
            }).then(() => {
              this.$bvModal.hide('admin-create-event-modal');
              this.$bvModal.hide('admin-edit-event-modal');
              this.resetForm();
            });
            return;
          }
        } finally {
          loader.stop();
        }
      }
    },
    async onSubmit() {
      this.validateInput();
      if (this.isBookingFormError) {
        return;
      }
      loader.start();
      const isArenaType = this.getArenaActivitiesName.includes(this.bookingInputForm.type.value);
      const selectType = isArenaType ? 'Arena' : this.bookingInputForm.type.value;
      let foundSameTimeFrameCloseSlot = false;
      if (selectType !== 'Arena') {
        await this.$store.dispatch('admin/showNotices', {
          activityType: selectType,
        });
        foundSameTimeFrameCloseSlot = this.notices.find((notice) => {
          const noticeStartDatetimeStringFormat = notice.startDatetime.replace(' ', 'T');
          const noticeStartDatetimeParse = Date.parse(noticeStartDatetimeStringFormat);
          const noticeEndDatetimeStringFormat = notice.endDatetime.replace(' ', 'T');
          const noticeEndDatetimeParse = Date.parse(noticeEndDatetimeStringFormat);

          const payloadStartDatetimeStringFormat = `${Date.parse(this.bookingInputForm.date.value).toString('yyyy-MM-dd')}T${this.bookingInputForm.timeFrom.value}:00`;
          const payloadStartDatetimeParse = Date.parse(payloadStartDatetimeStringFormat);
          const payloadEndDatetimeStringFormat = `${Date.parse(this.bookingInputForm.date.value).toString('yyyy-MM-dd')}T${this.bookingInputForm.timeTo.value}:00`;
          const payloadEndDatetimeParse = Date.parse(payloadEndDatetimeStringFormat);
          return (noticeStartDatetimeParse.between(payloadStartDatetimeParse, payloadEndDatetimeParse)
                    || noticeEndDatetimeParse.between(payloadStartDatetimeParse, payloadEndDatetimeParse)
          ) && !Date.equals(payloadStartDatetimeParse, noticeEndDatetimeParse)
                && !Date.equals(payloadEndDatetimeParse, noticeStartDatetimeParse);
        });
      }

      if (foundSameTimeFrameCloseSlot) {
        loader.stop();
        const h = this.$createElement;
        const messageVNode = h('div', [
          h('p', { class: ['font-weight-bold', 'mb-2'] }, ['Startdatum']),
          h('p', { class: ['mb-2'] }, [foundSameTimeFrameCloseSlot.startDatetime]),
          h('p', { class: ['font-weight-bold', 'mb-2'] }, ['Enddatum']),
          h('p', { class: ['mb-2'] }, [foundSameTimeFrameCloseSlot.endDatetime]),
          h('p', { class: ['font-weight-bold', 'mb-2'] }, ['Status']),
          h('p', { class: ['mb-2'] }, [foundSameTimeFrameCloseSlot.status]),
          h('p', { class: ['font-weight-bold', 'mb-2'] }, ['Information']),
          h('p', { class: ['mb-2'] }, [foundSameTimeFrameCloseSlot.information]),
        ]);
        const response = await this.$bvModal.msgBoxConfirm(messageVNode, {
          title: 'Your selected booking is under blocked time frame. Do you still want to continue?',
          okVariant: 'tab-orange',
          headerClass: 'p-2 py-3 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true,
        });
        if (!response) {
          return;
        }
      }

      let dateFormat;
      if (Date.parse(this.onCreateSelectDate).toString('yyyy-MM-dd') === Date.parse(this.bookingInputForm.date.value).toString('yyyy-MM-dd')) {
        dateFormat = Date.parse(this.bookingInputForm.date.value).toString('yyyy-MM-dd');
      } else {
        dateFormat = this.bookingInputForm.date.value;
      }

      if (selectType === 'Adventure Golf') {
        this.submitAddBooking();
        return;
      }

      try {
        const response = await AdminInformation.showBookings({
          dateFrom: dateFormat,
          dateTo: dateFormat,
          type: selectType,
        });
        let duplicateTimeEvents = [];
        if (response.status === 200) {
          const bookingState = response.data;
          duplicateTimeEvents = bookingState.filter((item) => {
            // Check for same selcted days value
            const isDuplicateDate = item.bookingDay === dateFormat;
            if (isDuplicateDate) {
              // Check for overlapping times
              const isSameSelectedTimeFromAndItemTimeTo = `${this.bookingInputForm.timeFrom.value}:00` === item.timeTo;
              if (isSameSelectedTimeFromAndItemTimeTo) {
                return false;
              } else {
                let isOverlappedTime = false;
                if (Date.parse(`${item.bookingDay}T${item.timeFrom}`) > Date.parse(`${item.bookingDay}T${this.bookingInputForm.timeFrom.value}:00`) &&
                                Date.parse(`${item.bookingDay}T${item.timeTo}`) < Date.parse(`${item.bookingDay}T${this.bookingInputForm.timeTo.value}:00`)
                ) {
                  isOverlappedTime =
                                Date.parse(`${item.bookingDay}T${item.timeFrom}`)
                                  .between(Date.parse(`${item.bookingDay}T${this.bookingInputForm.timeFrom.value}:00`), Date.parse(`${item.bookingDay}T${this.bookingInputForm.timeTo.value}:00`)) ||
                                Date.parse(`${item.bookingDay}T${item.timeTo}`)
                                  .between(Date.parse(`${item.bookingDay}T${this.bookingInputForm.timeFrom.value}:00`), Date.parse(`${item.bookingDay}T${this.bookingInputForm.timeTo.value}:00`));
                } else {
                  isOverlappedTime =
                                Date.parse(`${item.bookingDay}T${this.bookingInputForm.timeFrom.value}:00`)
                                  .between(Date.parse(`${item.bookingDay}T${item.timeFrom}`), Date.parse(`${item.bookingDay}T${item.timeTo}`)) ||
                                Date.parse(`${item.bookingDay}T${this.bookingInputForm.timeTo.value}:00`)
                                  .between(Date.parse(`${item.bookingDay}T${item.timeFrom}`), Date.parse(`${item.bookingDay}T${item.timeTo}`));
                }
                return isOverlappedTime;
              }

            } else {
              return false;
            }
          });
        }
        if (duplicateTimeEvents.length === 0) {
          this.submitAddBooking();
          return;
        }
        loader.stop();
        this.duplicateEvents = duplicateTimeEvents.map((item) => {
          return {
            ...item,
            timeTo: item.timeTo.slice(0, -3),
            timeFrom: item.timeFrom.slice(0, -3),
          };
        });
        this.$bvModal.show('admin-duplicate-event-modal');
      } catch(err) {
        loader.stop();
        this.$bvModal.msgBoxOk(err, {
          title: 'Add event error',
          okVariant: 'tab-orange',
          headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
          footerClass: 'p-2 border-top-0',
          centered: true,
        });
      }
    },
    async submitAddBooking() {
      if (this.replaceEvents.length !== 0) {
        try {
          await AdminInformation.cancelBooking({
            identification: this.replaceEvents.join(),
            eliminator: 'p',
          });
          this.replaceEvents = [];
        } catch (err) {
          loader.stop();
          this.$bvModal.msgBoxOk(err, {
            title: 'Replace event error',
            okVariant: 'tab-orange',
            headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
            footerClass: 'p-2 border-top-0',
            centered: true,
          });
          return;
        }
      }

      let payload = {};
      Object.keys(this.bookingInputForm).forEach((key) => {
        payload = {
          ...payload,
          [key]: this.bookingInputForm[key].value,
        };
      });

      let dateFormat;
      if (Date.parse(this.onCreateSelectDate).toString('yyyy-MM-dd') === Date.parse(payload.date).toString('yyyy-MM-dd')) {
        dateFormat = Date.parse(payload.date).toString('yyyy-MM-dd');
      } else {
        dateFormat = this.bookingInputForm.date.value;
      }

      payload = {
        ...payload,
        day: dateFormat,
        timeFrom: `${payload.timeFrom}:00`,
        timeTo: `${payload.timeTo}:00`,
        pageConfig: this.bookingInputForm.type.value === 'Adventure Golf'
          ? 'adventure_golf'
          : 'homepage_arena',
        teamSize: this.bookingInputForm.type.value === 'Adventure Golf'
          ? `${this.adventureBookingSelectQty['ERWACHSENE'] || 0}|${this.adventureBookingSelectQty['KINDER']}|${this.adventureBookingSelectQty['FAMILIE']}|0`
          : '',
      };

      if (this.selectBookingType === 'Adventure Golf' || this.bookingInputForm.type.value === 'Adventure Golf') {
        payload = {
          ...payload,
          filters: {
            maName: this.adventureBookingInputMa.substring(0, 2),
            invoice: this.adventureBookingInputInvoice,
          },
        };
      }

      try {
        const response = await AdminInformation.addBooking(payload);
        if (response.status === 200 && response.data.successful === false) {
          loader.stop();
          this.$bvModal.msgBoxOk(response.data.information, {
            title: 'Add event Error',
            okVariant: 'tab-orange',
            headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
            footerClass: 'p-2 border-top-0',
            centered: true,
          });
          return;
        }
        loader.stop();
        this.$bvModal.msgBoxOk('Add successful', {
          title: 'Add event Information',
          okVariant: 'tab-orange',
          headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
          footerClass: 'p-2 border-top-0',
          centered: true,
        }).then(() => {
          this.loadEventTable();
          this.$bvModal.hide('admin-create-event-modal');
          this.resetForm();
        });
      } catch (err) {
        loader.stop();
        const h = this.$createElement;
        if (!err.response) {
          this.$bvModal.msgBoxOk(err, {
            title: 'Create event Error',
            okVariant: 'tab-orange',
            headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
            footerClass: 'p-2 border-top-0',
            centered: true,
          });
          return;
        }
        if (!err.response.data.details) {
          this.$bvModal.msgBoxOk(err, {
            title: 'Create event Error',
            okVariant: 'tab-orange',
            headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
            footerClass: 'p-2 border-top-0',
            centered: true,
          });
          return;
        }
        const detailMsgList = err.response.data.details.map((msg) => {
          return h('div', [
            h('p', { class: ['font-weight-bold', 'mb-4'] }, [`Type: ${msg.type}`]),
            h('p', { class: ['font-weight-bold', 'mb-2'] }, ['Date & Time range']),
            h('p', { class: ['mb-4'] }, [msg.dateTime]),
            h('p', { class: ['font-weight-bold', 'mb-2'] }, ['Your selected date & time range']),
            h('p', { class: ['mb-2'] }, [`${payload.date} ${payload.timeFrom} - ${payload.timeTo}`]),
          ]);
        });
        const messageVNode = h('div', [
          h('p', { class: ['font-weight-bold', 'text-danger', 'mb-4'] }, [`${err.response.data.information}`]),
          h('div', detailMsgList),
        ]);
        this.$bvModal.msgBoxOk(messageVNode, {
          title: 'Create event Error',
          okVariant: 'tab-orange',
          headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
          footerClass: 'p-2 border-top-0',
          centered: true,
        });
      }
    },
    async submitEditBooking() {
      if (this.replaceEvents.length !== 0) {
        try {
          await AdminInformation.cancelBooking({
            identification: this.replaceEvents.join(),
            eliminator: 'p',
          });
          this.replaceEvents = [];
        } catch (err) {
          loader.stop();
          this.$bvModal.msgBoxOk(err, {
            title: 'Replace event error',
            okVariant: 'tab-orange',
            headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
            footerClass: 'p-2 border-top-0',
            centered: true,
          });
          return;
        }
      }

      let payload = {};
      Object.keys(this.bookingInputForm).forEach((key) => {
        payload = {
          ...payload,
          [key]: this.bookingInputForm[key].value,
        };
      });

      payload = {
        ...payload,
        identification: this.editId,
        day: payload.date,
        timeFrom: `${payload.timeFrom}:00`,
        timeTo: `${payload.timeTo}:00`,
        pageConfig: payload.type === 'Adventure Golf'
          ? 'adventure_golf'
          : 'homepage_arena',
      };

      if (this.selectBookingType === 'Adventure Golf' || this.bookingInputForm.type.value === 'Adventure Golf') {
        payload = {
          ...payload,
          teamSize: payload.type === 'Adventure Golf'
            ? `${this.adventureBookingSelectQty['ERWACHSENE'] || 0}|${this.adventureBookingSelectQty['KINDER']}|${this.adventureBookingSelectQty['FAMILIE']}|0`
            : '',
          filters: {
            maName: this.adventureBookingInputMa.substring(0, 2),
            invoice: this.adventureBookingInputInvoice,
          },
        };
      }

      try {
        await this.$store.dispatch('admin/alterBooking', payload);
        loader.stop();
        this.$bvModal.msgBoxOk('Edit successful', {
          title: 'Edit event Information',
          okVariant: 'tab-orange',
          headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
          footerClass: 'p-2 border-top-0',
          centered: true,
        }).then(() => {
          this.onHideEditModal();
          this.$bvModal.hide('admin-edit-event-modal');
          this.resetForm();
        });
      } catch (err) {
        loader.stop();
        const h = this.$createElement;
        if (!err.response.data.details) {
          this.$bvModal.msgBoxOk(err, {
            title: 'Edit Error',
            okVariant: 'tab-orange',
            headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
            footerClass: 'p-2 border-top-0',
            centered: true,
          });
          return;
        }
        const detailMsgList = err.response.data.details.map((msg) => {
          return h('div', [
            h('p', { class: ['font-weight-bold', 'mb-4'] }, [`Type: ${msg.type}`]),
            h('p', { class: ['font-weight-bold', 'mb-2', 'text-danger'] }, ['Date & Time range']),
            h('p', { class: ['mb-4', 'text-danger'] }, [msg.dateTime]),
            h('p', { class: ['font-weight-bold', 'mb-2'] }, ['Your selected date & time range']),
            h('p', { class: ['mb-4'] }, [`${payload.date} ${payload.timeFrom} - ${payload.timeTo}`]),
          ]);
        });
        const messageVNode = h('div', [
          h('p', { class: ['font-weight-bold', 'text-danger', 'mb-4'] }, [`${err.response.data.information}`]),
          h('div', detailMsgList),
        ]);
        this.$bvModal.msgBoxOk(messageVNode, {
          title: 'Edit Error',
          okVariant: 'tab-orange',
          headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
          footerClass: 'p-2 border-top-0',
          centered: true,
        });
      }
    },
    setCalendarElement() {
      // Vuetify calendar custom weekday character
      const newWeekDayTr = document.createElement('tr');
      const weekdaysName = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];
      const groupedWeekdayThElements = weekdaysName.map((item) => {
        const th = document.createElement('th');
        th.innerText = item;
        return th;
      });

      groupedWeekdayThElements.forEach((item) => {
        newWeekDayTr.appendChild(item);
      });

      const weekdayParent = document.getElementById('adminBookingCalendar')
        .children[1] // body
        .children[0] // div
        .children[1] // table-wrapper
        .children[0] // table
        .children[0]; // thead

      const oldWeekdayTr = document.getElementById('adminBookingCalendar')
        .children[1] // body
        .children[0] // div
        .children[1] // table-wrapper
        .children[0] // table
        .children[0] // thead
        .children[0]; // weekday tr

      weekdayParent.replaceChild(newWeekDayTr, oldWeekdayTr);
    },
    async onSubmitEdit() {
      this.validateInput();
      if (this.isBookingFormError) {
        return;
      }
      loader.start();
      const isArenaType = this.getArenaActivitiesName.includes(this.bookingInputForm.type.value);
      const selectType = isArenaType ? 'Arena' : this.bookingInputForm.type.value;
      let foundSameTimeFrameCloseSlot = false;
      if (selectType !== 'Arena') {
        await this.$store.dispatch('admin/showNotices', {
          activityType: selectType,
        });
        foundSameTimeFrameCloseSlot = this.notices.find((notice) => {
          const noticeStartDatetimeStringFormat = notice.startDatetime.replace(' ', 'T');
          const noticeStartDatetimeParse = Date.parse(noticeStartDatetimeStringFormat);
          const noticeEndDatetimeStringFormat = notice.endDatetime.replace(' ', 'T');
          const noticeEndDatetimeParse = Date.parse(noticeEndDatetimeStringFormat);

          const payloadStartDatetimeStringFormat = `${Date.parse(this.bookingInputForm.date.value).toString('yyyy-MM-dd')}T${this.bookingInputForm.timeFrom.value}:00`;
          const payloadStartDatetimeParse = Date.parse(payloadStartDatetimeStringFormat);
          const payloadEndDatetimeStringFormat = `${Date.parse(this.bookingInputForm.date.value).toString('yyyy-MM-dd')}T${this.bookingInputForm.timeTo.value}:00`;
          const payloadEndDatetimeParse = Date.parse(payloadEndDatetimeStringFormat);
          return (noticeStartDatetimeParse.between(payloadStartDatetimeParse, payloadEndDatetimeParse)
                    || noticeEndDatetimeParse.between(payloadStartDatetimeParse, payloadEndDatetimeParse)
          ) && !Date.equals(payloadStartDatetimeParse, noticeEndDatetimeParse)
                && !Date.equals(payloadEndDatetimeParse, noticeStartDatetimeParse);
        });
      }

      if (foundSameTimeFrameCloseSlot) {
        loader.stop();
        const h = this.$createElement;
        const messageVNode = h('div', [
          h('p', { class: ['font-weight-bold', 'mb-2'] }, ['Startdatum']),
          h('p', { class: ['mb-2'] }, [foundSameTimeFrameCloseSlot.startDatetime]),
          h('p', { class: ['font-weight-bold', 'mb-2'] }, ['Enddatum']),
          h('p', { class: ['mb-2'] }, [foundSameTimeFrameCloseSlot.endDatetime]),
          h('p', { class: ['font-weight-bold', 'mb-2'] }, ['Status']),
          h('p', { class: ['mb-2'] }, [foundSameTimeFrameCloseSlot.status]),
          h('p', { class: ['font-weight-bold', 'mb-2'] }, ['Information']),
          h('p', { class: ['mb-2'] }, [foundSameTimeFrameCloseSlot.information]),
        ]);
        const response = await this.$bvModal.msgBoxConfirm(messageVNode, {
          title: 'Your selected booking is under blocked time frame. Do you still want to continue?',
          okVariant: 'tab-orange',
          headerClass: 'p-2 py-3 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true,
        });
        if (!response) {
          return;
        }
      }

      let dateFormat;
      if (Date.parse(this.onCreateSelectDate).toString('yyyy-MM-dd') === Date.parse(this.bookingInputForm.date.value).toString('yyyy-MM-dd')) {
        dateFormat = Date.parse(this.bookingInputForm.date.value).toString('yyyy-MM-dd');
      } else {
        dateFormat = this.bookingInputForm.date.value;
      }

      if (selectType === 'Adventure Golf') {
        this.submitEditBooking();
        return;
      }

      const response = await AdminInformation.showBookings({
        dateFrom: dateFormat,
        dateTo: dateFormat,
        type: selectType,
      });
      let duplicateTimeEvents = [];
      if (response.status === 200) {
        const bookingState = response.data;
        duplicateTimeEvents = bookingState.filter((item) => {
          // Check for same selcted days value
          const isOtherBooking = item.identification !== this.editId;
          const isDuplicateDate = item.bookingDay === dateFormat;
          if (isDuplicateDate && isOtherBooking) {
            // Check for overlapping times
            const isSameSelectedTimeFromAndItemTimeTo = `${this.bookingInputForm.timeFrom.value}:00` === item.timeTo;
            if (isSameSelectedTimeFromAndItemTimeTo) {
              return false;
            } else {
              let isOverlappedTime = false;

              if (Date.parse(`${item.bookingDay}T${item.timeFrom}`) > Date.parse(`${item.bookingDay}T${this.bookingInputForm.timeFrom.value}:00`) &&
                        Date.parse(`${item.bookingDay}T${item.timeTo}`) < Date.parse(`${item.bookingDay}T${this.bookingInputForm.timeTo.value}:00`)
              ) {
                isOverlappedTime =
                        Date.parse(`${item.bookingDay}T${item.timeFrom}`)
                          .between(Date.parse(`${item.bookingDay}T${this.bookingInputForm.timeFrom.value}:00`), Date.parse(`${item.bookingDay}T${this.bookingInputForm.timeTo.value}:00`)) ||
                        Date.parse(`${item.bookingDay}T${item.timeTo}`)
                          .between(Date.parse(`${item.bookingDay}T${this.bookingInputForm.timeFrom.value}:00`), Date.parse(`${item.bookingDay}T${this.bookingInputForm.timeTo.value}:00`));
              } else {
                isOverlappedTime =
                        Date.parse(`${item.bookingDay}T${this.bookingInputForm.timeFrom.value}:00`)
                          .between(Date.parse(`${item.bookingDay}T${item.timeFrom}`), Date.parse(`${item.bookingDay}T${item.timeTo}`)) ||
                        Date.parse(`${item.bookingDay}T${this.bookingInputForm.timeTo.value}:00`)
                          .between(Date.parse(`${item.bookingDay}T${item.timeFrom}`), Date.parse(`${item.bookingDay}T${item.timeTo}`));
              }
              return isOverlappedTime;
            }
          } else {
            return false;
          }
        });
      }
      if (duplicateTimeEvents.length === 0) {
        this.submitEditBooking();
        return;
      }
      loader.stop();
      this.duplicateEvents = duplicateTimeEvents.map((item) => {
        return {
          ...item,
          timeTo: item.timeTo.slice(0, -3),
          timeFrom: item.timeFrom.slice(0, -3),
        };
      });
      this.$bvModal.show('admin-duplicate-event-modal');
    },
    onSubmitAfterHandleDuplicate() {
      this.$bvModal.hide('admin-duplicate-event-modal');
      loader.start();
      this.onReplaceAll();
      this.duplicateEvents = [];
      if (this.editId === 0) {
        this.submitAddBooking();
      } else {
        this.submitEditBooking();
      }
    },
    async loadEventTable(isInit = true) {
      if (this.tabIndex === 0 && !isInit) {
        const tableRows = document.querySelector('#adminBookingTableId').children[1].children;
        tableRows.forEach((row, index) => {
          if (index % 2 === 0) {
            row.style.backgroundColor = 'rgba(28, 28, 28, 0.05)';
          } else {
            row.style.backgroundColor = '#ffffff';
          }
        });
        const foundElements = document.querySelectorAll(`#golfGameAnchor${this.selectDate}`);
        if (foundElements.length > 0) {
          this.$scrollTo(`#golfGameAnchor${this.selectDate}`, 300, {
            offset: -200,
          });
          foundElements.forEach((el) => {
            el.parentElement.parentElement.style.backgroundColor = 'rgba(240, 144, 9, 0.35)';
          });
        } else {
          const closestDate = this.getClosestDate(this.selectDate);
          const year = closestDate.getFullYear();
          const month = closestDate.getMonth() < 10 ? `0${closestDate.getMonth() + 1}` : closestDate.getMonth() + 1;
          const date = closestDate.getDate() < 10 ? `0${closestDate.getDate()}` : closestDate.getDate();

          const anchor = `#golfGameAnchor${year}-${month}-${date}`;
          this.$scrollTo(anchor, 300, {
            offset: -200,
          });
          const foundElements = document.querySelectorAll(anchor);
          foundElements.forEach((el) => {
            el.parentElement.parentElement.style.backgroundColor = 'rgba(240, 144, 9, 0.35)';
          });
        }
        return;
      }
      this.filter = '';
      this.isLoading = true;
      await this.$store.dispatch('base/getAllActivities');
      let requestEventPayload = {
        dateFrom: this.selectDate,
        dateTo: this.selectDate,
      };
      if (this.tabIndex === 0) {
        requestEventPayload = {
          filters: {
            frequency: 'monthly',
          },
        };
      }
      await this.$store.dispatch('admin/showAllEvents', requestEventPayload);
      if (this.cntFetchTime > 0) {
        this.cntFetchTime--;
      }
      this.isLoading = false;
      if (this.tabIndex === 0 && isInit) {
        setTimeout(() => {
          const tableRows = document.querySelector('#adminBookingTableId').children[1].children;
          tableRows.forEach((row, index) => {
            if (index % 2 === 0) {
              row.style.backgroundColor = 'rgba(28, 28, 28, 0.05)';
            } else {
              row.style.backgroundColor = '#ffffff';
            }
          });
          const foundElements = document.querySelectorAll(`#golfGameAnchor${this.selectDate}`);
          if (foundElements.length > 0) {
            this.$scrollTo(`#golfGameAnchor${this.selectDate}`, 300, {
              offset: -200,
            });
            foundElements.forEach((el) => {
              el.parentElement.parentElement.style.backgroundColor = 'rgba(240, 144, 9, 0.35)';
            });
          } else {
            this.$bvModal.msgBoxOk(`Kein Buchung in ${this.selectDate} gefunden`, {
              title: 'Buchung nicht gefunden',
              okVariant: 'tab-orange',
              headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
              footerClass: 'p-2 border-top-0',
              centered: true,
            });
          }
        }, 200);
      }
    },
    onSearchTable: _.debounce(async function(e) {
      this.cntFetchTime++;
      this.isLoading = true;
      const val = e;
      if (val === '') {
        this.loadEventTable();
        return;
      }
      let searchPayload = {
        dateFrom: '',
        dateTo: '',
        filters: {
          searchString: val,
        },
      };
      if (this.tabIndex === 0) {
        searchPayload = {
          filters: {
            frequency: 'monthly',
            searchString: val,
          },
        };
      }
      await this.$store.dispatch('admin/showAllEvents', searchPayload);
      this.cntFetchTime--;
    }, 500),
    resetForm() {
      Object.values(initInputForm).forEach((item) => {
        this.bookingInputForm = {
          ...this.bookingInputForm,
          [item.key]: {
            ...item,
            value: '',
          },
        };
      });
      Object.keys(initErrorForm).forEach((key) => {
        this.isErrorBookingForm = {
          ...this.isErrorBookingForm,
          [key]: initErrorForm[key],
        };
      });
      this.adventureBookingSelectQty = {
        'ERWACHSENE': null,
        'KINDER': 0,
        'FAMILIE': 0,
      };
      this.adventureBookingInputMa = '';
      this.adventureBookingInputInvoice = '0';

      this.is24Hour = false;
    },
    onReplaceAll() {
      this.replaceEvents = this.duplicateEvents.slice().map((item) => item.identification);
      this.duplicateEvents = [];
    },
    onSkipAll() {
      this.duplicateEvents = [];
    },
    handleLeave(event) {
      this.duplicateEvents = this.duplicateEvents.filter((item) => item.identification !== event.identification);
    },
    handleReplace(event) {
      this.replaceEvents.push(event.identification);
      this.duplicateEvents = this.duplicateEvents.filter((item) => item.identification !== event.identification);
    },
    onCancelDuplicateEvent() {
      this.$bvModal.hide('admin-duplicate-event-modal');
      this.replaceEvents = [];
    },
    onClickDetail(item) {
      this.selectBookingDetail = item;
      this.$bvModal.show('admin-booking-detail-modal');
    },
    onClick24Hour() {
      if (this.is24Hour) {
        this.bookingInputForm.timeFrom.value = '00:00';
        this.bookingInputForm.timeTo.value = '23:59';
      } else {
        this.bookingInputForm.timeFrom.value = '';
        this.bookingInputForm.timeTo.value = '';
        this.$refs.timeFromRef.clearTime();
        this.$refs.timeToRef.clearTime();
      }
    },
    getClosestDate(checkDate) {
      if (this.items.length === 0) {
        return;
      }

      const itemDates = this.items.map((item) => {
        const dateSplits = item.bookingDay.split('-');
        const year = dateSplits[0];
        const month = parseInt(dateSplits[1]) - 1;
        const date = dateSplits[2];
        return new Date(year, month, date);
      });

      const checkDateSplits = checkDate.split('-');
      const year = checkDateSplits[0];
      const month = parseInt(checkDateSplits[1]) - 1;
      const date = checkDateSplits[2];
      const diffDate = new Date(year, month, date);

      const sortedDatesByDifferentRange = itemDates.sort((a, b) => {
        let distanceA = Math.abs(diffDate - a);
        let distanceB = Math.abs(diffDate - b);
        return distanceA - distanceB; // sort a before b when the distance is smaller
      });

      if (sortedDatesByDifferentRange[0] > diffDate) {
        return sortedDatesByDifferentRange[0];
      }

      const datesGreaterThanChecker = sortedDatesByDifferentRange.filter((item) => item > diffDate);
      return datesGreaterThanChecker.length === 0
        ? sortedDatesByDifferentRange[0]
        : datesGreaterThanChecker[0];
    },
    getAdventureGolfTeamSizeSum(sizeString) {
      const sizes = sizeString.split('|');
      let sum = 0;
      sizes.forEach((size, index) => {
        if (index !== 2) {
          sum += parseInt(size);
        } else {
          sum += parseInt(size) * 4;
        }
      });
      return sum;
    },
    getAdventureGolfTeamTicketAmount(sizeString) {
      const sizes = sizeString.split('|');
      let ticketAmount = '';
      sizes.forEach((size, index) => {
        if (size !== '0') {
          ticketAmount += `<p>${golfPrice[index].label}: ${size}</p>`;
        }
      });
      return `<div>${ticketAmount}</div>`;
    },
    getAdventureGolfPriceSum(sizeString) {
      const sizes = sizeString.split('|');
      let price = 0;
      sizes.forEach((size, index) => {
        if (size !== '0') {
          price += parseInt(size) * golfPrice[index].price;
        }
      });
      const intValue = parseFloat(price);
      if (!intValue) {
        return '-';
      }
      return `€ ${intValue.toFixed(2).toLocaleString()}`;
    },
    onHideEditModal() {
      this.editId = 0;
    },
    isPast(datetime) {
      const today = new Date();
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);
      today.setMilliseconds(0);
      const inputDate = new Date(datetime);
      return today.getTime() > inputDate.getTime();
    },
  },
};
</script>
