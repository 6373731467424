<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="size"
    :height="size"
  ><path
    :fill="color"
    d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
  /></svg>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: '20px',
    },
    color: {
      type: String,
      default: '#333333',
    },
  },
};
</script>
